import React from 'react';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { Typography, Link } from '@tkww/the-bash-frontend';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useInView } from 'react-intersection-observer';

import trustedBadge from 'public/images-next/plannerValueProposition/trustedBadge.svg';
import creditCardShield from 'public/images-next/plannerValueProposition/creditCardShield.svg';
import fiveStarPhoneMail from 'public/images-next/plannerValueProposition/fiveStarPhoneMail.svg';
import background from 'public/images-next/plannerValueProposition/background.svg';
import { componentViewed, plannerValuePropositionOurGuaranteeClick } from 'state/modules/analytics';
import SectionContainer from 'components/grid/SectionContainer';

const StyledValueProposition = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    '& > :not(:last-child)': {
      marginRight: 24,
    },
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    textAlign: 'left',
  },
}));

const StyledValueItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '250px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    maxWidth: '100%',
    paddingBottom: 40,
  },
  '& .title': {
    [theme.breakpoints.down('md')]: {
      margin: '0 0 8px 0',
    },
  },
}));

const StyledImgIcon = styled('img')(({ theme }) => ({
  height: 110,
  width: 110,
  [theme.breakpoints.down('md')]: {
    height: 90,
    width: 90,
    marginRight: 20,
  },
}));

export const PlannerValueProposition = (props) => {
  const dispatch = useDispatch();

  const { segmentProps, categorySingular, categoryPlural, location, ...restProps } = props;

  const getH2SubCopy = () => {
    if (categoryPlural && location) {
      return `Find ${categoryPlural} in ${location} to bring your event to life.`;
    } else if (categoryPlural) {
      return `Find ${categoryPlural} to bring your event to life.`;
    }
    return 'Find local vendors to bring your celebration to life.';
  };

  const getTrustedVendorsPillarSubCopy = () => {
    if (categoryPlural && location) {
      return `With verified reviews and thousands of ratings, it's easy to book the perfect ${categoryPlural} for all types of events in ${location}—no matter how big or small.`;
    } else if (categoryPlural) {
      return `With verified reviews and thousands of ratings, it's easy to book the perfect ${categoryPlural} for all types of events—no matter how big or small.`;
    }
    return "With verified reviews and thousands of ratings, it's easy to book the perfect vendor for all types of events—no matter how big or small.";
  };

  const getFreeBookingPlatformSubCopy = () => {
    if (categorySingular) {
      return `It's free to book and make secure online payments through The Bash. Once the booking for your selected ${categorySingular} is confirmed, it's covered by `;
    }
    return "It's free to book and make secure online payments through The Bash. Once the booking is confirmed, it's covered by ";
  };

  const dispatchPlannerGuaranteeClick = () => {
    dispatch(plannerValuePropositionOurGuaranteeClick(segmentProps));
  };

  const onViewChange = (inView) => {
    if (inView) dispatch(componentViewed({ component: 'value prop' }));
  };

  const { ref } = useInView({
    threshold: 0.5,
    onChange: onViewChange,
    triggerOnce: true,
    initialInView: true,
  });

  return (
    <Box ref={ref} {...restProps}>
      <SectionContainer
        sx={{
          padding: { sm: '56px 0 60px 0', md: '56px 0 40px 0' },
          backgroundImage: `url(${background})`,
        }}
      >
        <Box
          component="section"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '50px',
          }}
        >
          <Typography
            variant="h2"
            sx={{ textAlign: 'center', margin: { xs: '56px 0px 4px 0px', md: '0px 0px 4px 0px' } }}
          >
            Plan, book, celebrate—with confidence
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {getH2SubCopy()}
          </Typography>
        </Box>
        <StyledValueProposition>
          <StyledValueItem>
            <StyledImgIcon src={trustedBadge} alt="Badge with check mark" />
            <Box>
              <Typography className="title" variant="h4" component="h3">
                Trusted Vendors
              </Typography>
              <Typography variant="body1">{getTrustedVendorsPillarSubCopy()}</Typography>
            </Box>
          </StyledValueItem>
          <StyledValueItem>
            <StyledImgIcon src={creditCardShield} alt="credit card protected by a shield" />
            <Box>
              <Typography className="title" variant="h4" component="h3">
                Free Booking Platform
              </Typography>
              <Typography variant="body1">
                {getFreeBookingPlatformSubCopy()}
                <Link path="/guarantee" onClick={dispatchPlannerGuaranteeClick}>
                  our Booking Guarantee.
                </Link>
              </Typography>
            </Box>
          </StyledValueItem>
          <StyledValueItem>
            <StyledImgIcon src={fiveStarPhoneMail} alt="envelope and phone with 5 stars" />
            <Box>
              <Typography className="title" variant="h4" component="h3">
                Top-Rated Service
              </Typography>
              <Typography variant="body1">
                {
                  "Our US based team of Account Specialists are here to help every step of the way. Since 1997, we've connected planners with vendors for over half a million events."
                }
              </Typography>
            </Box>
          </StyledValueItem>
        </StyledValueProposition>
      </SectionContainer>
    </Box>
  );
};

PlannerValueProposition.propTypes = {
  categorySingular: PropTypes.string,
  categoryPlural: PropTypes.string,
  location: PropTypes.string,
  segmentProps: PropTypes.shape({
    name: PropTypes.string,
    servicePageType: PropTypes.string,
    serviceCategory: PropTypes.string,
    searchResultsType: PropTypes.string,
    searchResultsFormat: PropTypes.string,
  }),
};

PlannerValueProposition.defaultProps = {
  categorySingular: '',
  categoryPlural: '',
  segmentProps: {},
};

export default PlannerValueProposition;
